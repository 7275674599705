import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reglementsduconcours',
  templateUrl: './reglementsduconcours.component.html',
  styleUrls: ['./reglementsduconcours.component.css']
})
export class ReglementsduconcoursComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
