import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { InscriptionService } from 'src/app/services/inscription.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit, OnInit {
  public Todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  rep;
  listegroup: any = [];
  displayedColumns: string[] = ['GroupDateInscrit', 'IDParticipation', 'GroupNom', 'GroupVillePreselection', 'GroupRespoNomPrenoms', 'GroupContact', 'GroupEmail', 'GroupEmailConfim', 'boutons'];
  // dataSource: MatTableDataSource<any>;
  IDSuppression = '';
  isLoadingResults = true;
  dataSource = new MatTableDataSource(this.listegroup);
  RechercheForm: FormGroup;
  public PrintListeInscrit: any = [];
  ville = new FormControl('');
  filtre = new FormControl('');

  filterValues: any = {
    ville: '',
    filtre: ''
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  fdatedebut: any;
  fdatefin: any;
  TotalInscrit: any;

  /***************************** */
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  titl = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement, { dateNF: 'mm/dd/yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.Todate + '-Etat-Inscription-Wozo-Vacances-2022.xlsx');
  }
  /***************************** */

  constructor(
    private toastr: ToastrService,
    private inscriptionService: InscriptionService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder) {

    this.RechercheForm = this.formBuilder.group({
      date_debut: ['', Validators.required],
      date_fin: ['', Validators.required],
      filtre: ['']
    });
  }

  ngOnInit() {
    this.afficheliste();
    /* this.initForm();*/
  }

  initForm() {
    this.isLoadingResults = false;
    this.RechercheForm = this.formBuilder.group({
      date_debut: ['', Validators.required],
      date_fin: ['', Validators.required],
      ville: [''],
      filtre: ['']
    });

  }

  afficheliste() {
    this.isLoadingResults = true;
    this.inscriptionService.listeInscription().subscribe(data => {
      this.rep = data;
      this.listegroup = this.rep.results;
      this.dataSource.data = this.rep.results;
      this.PrintListeInscrit = this.rep.results;
      this.TotalInscrit = this.rep.results.length;

      this.isLoadingResults = false;
    });
  }

  ngAfterViewInit() {
    /*this.afficheliste();*/
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filtreville(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filterPredicate(this.dataSource.data, filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    /* alert(this.dataSource.data.filter.length);
     this.TotalInscrit = this.dataSource.data.filter.length;*/

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /*
    private fieldListener() {
      this.ville.valueChanges
        .subscribe(
          ville => {
            this.filterValues.ville = ville;
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        )
      this.filtre.valueChanges
        .subscribe(
          filtre => {
            this.filterValues.filtre = filtre;
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        )
    }
  
    clearFilter() {
      this.filtre.setValue('');
      this.ville.setValue('');
    }
  
    private createFilter(): (data, filter: string) => boolean {
      let filterFunction = function (contact, filter): boolean {
        let searchTerms = JSON.parse(filter);
  
        return data.filtre.indexOf(searchTerms.filtre) !== -1;
         // && contact.source.indexOf(searchTerms.source) !== -1;
      }
  
      return filterFunction;
    }*/
  onSubmitForm(f) {
    this.isLoadingResults = true;
    const RechercheFormData = new FormData();
    RechercheFormData.append('date_debut', f.date_debut);
    RechercheFormData.append('date_fin', f.date_fin);
    RechercheFormData.append('ville', f.ville);
    RechercheFormData.append('filtre', f.filtre);

    this.fdatedebut = f.date_debut;
    this.fdatefin = f.date_fin;
    this.inscriptionService.listeInscriptionfiltre(RechercheFormData).subscribe(result => {
      this.rep = result;
      this.listegroup = this.rep.results;
      this.dataSource.data = this.rep.results;
      this.PrintListeInscrit = this.rep.results;
      this.TotalInscrit = this.rep.results.length;
      this.isLoadingResults = false;
    });

  }

  /// FILTRE

  //////

  deconnexion() {
    window.location.href = 'backend/login';
    localStorage.clear();
  }
}

