import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InscriptionService } from 'src/app/services/inscription.service';

@Component({
  selector: 'app-confirmation-inscription',
  templateUrl: './confirmation-inscription.component.html',
  styleUrls: ['./confirmation-inscription.component.css']
})
export class ConfirmationInscriptionComponent implements OnInit {
  IDparticipant;
  isLoadingResults;
  resultIDparticipant: any;
  GroupVillePreselection: any;
  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private toastr: ToastrService,
    private inscriptionService: InscriptionService,
  ) { }

  ngOnInit(): void {
    this.IDparticipant = this.actRoute.snapshot.params['id'];
    this.loadVerificationInscription(this.IDparticipant);
    /*alert(this.IDparticipant);*/
  }





  loadVerificationInscription(ID) {
    this.isLoadingResults = true;
    const VerificationFormData = new FormData();
    VerificationFormData.append('EmailGroupe', ID);
    this.inscriptionService.verificationGroupeInscriptionWozo(VerificationFormData).subscribe(result => {
      if (result.success === true) {
        this.toastr.success(result.message, 'SUCCES');

        this.resultIDparticipant = result.results[0].IDParticipation;
        this.GroupVillePreselection = result.results[0].GroupVillePreselection;
        this.isLoadingResults = false;

      } else {
        this.toastr.error(result.message, 'MESSAGE D\'ERREUR');
        this.router.navigate(['/registrations/add']);
      }

    });
  }



}
