<p>reglementsduconcours works!</p>
<div class="wrapper" style="background-image: url('assets/images/bg-registration-form-2.jpg');">
    <div class="inner">
  
        <form action="">
          <h3>Formulaire d'inscription WOZO VACANCES 2022</h3>
          <div class="form-wrapper">
              <label for="">NOM DU GROUPE</label>
              <input type="text" class="form-control" placeholder="Nom du groupe">
            </div>
            <div class="form-wrapper">
              <label for="">CONTACT</label>
              <input type="text" class="form-control" placeholder="Contact du responsable du groupe" maxlength="10">
            </div>
            <div class="form-wrapper">
              <label for="">EMAIL</label>
              <input type="text" class="form-control" placeholder="Email du responsable du groupe">
            </div>
            <div class="form-wrapper">
              <label for="">VILLE DE PRESELECTION</label>
              <select name="pets" id="pet-select" class="form-control">
                <option value="ABIDJAN">ABIDJAN</option>
                <option value="ADZOPE">ADZOPE</option>
                <option value="AGNIBILEKRO">AGNIBILEKRO</option>
                <option value="BOUAKE">BOUAKE</option>
                <option value="DUEKOUE">DUEKOUE</option>
                <option value="DALOA">DALOA</option>
                <option value="KORHOGO">KORHOGO</option>
              </select>
         
            </div>
  
          <div class="checkbox">
            <label>
              <input type="checkbox"> J'ai lu et accepté les règlements du concours..  
              <span class="checkmark"></span>
            </label>
          </div>
          <a routerLink='reglements/wozo'>voir>></a>
          <button>s'enregistrer</button>
        </form>
      </div>
  </div>