import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InscriptionsComponent } from './page/inscriptions/inscriptions.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe, DecimalPipe } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReglementsduconcoursComponent } from './page/reglementsduconcours/reglementsduconcours.component';
import { ConfirmationInscriptionComponent } from './page/confirmation-inscription/confirmation-inscription.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogBoxComponent } from './page/dialog-box/dialog-box.component';
import { LoginComponent } from './page/administration/login/login.component';
import { DashboardComponent } from './page/administration/dashboard/dashboard.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { NgxPrintModule } from 'ngx-print';


@NgModule({
  declarations: [
    AppComponent,
    InscriptionsComponent,
    ReglementsduconcoursComponent,
    ConfirmationInscriptionComponent,
    DialogBoxComponent,
    LoginComponent,
    DashboardComponent,

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    NgxPrintModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    ToastrModule.forRoot({
      timeOut: 4500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgbModule,
  ],
  providers: [DatePipe, DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
