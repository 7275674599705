
<link href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.0.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>


<div class="wrapper" style="background-image: url('assets/images/bg-registration-form-2.jpg');">
<div class="container">
    <div class="row">
        <div class="col-sm-6 col-md-4 col-md-offset-4">
            <h1 class="text-center login-title" style="text-transform: uppercase;">Connectez-vous pour continuer</h1>
            <div class="account-wall">
                <div class="text-center">
                <img class="" src="http://wozovacances.rti.ci/assets/images/logo-wozo-2021.jpg"
                    alt="">
                </div>
                <form class="form-signin" [formGroup]="ValidedForm" (ngSubmit)="login(ValidedForm.value)">
                <input type="text" class="form-control" formControlName="login" placeholder="Email" required autofocus>
                <input type="password" class="form-control" placeholder="Password" formControlName="motdepasse"  required>
                <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!ValidedForm.valid">
                    Se connecter</button>
            
                <a href="#" class="pull-right need-help"></a><span class="clearfix"></span>
                </form>
            </div>
          
        </div>
    </div>
</div>
</div>