import { Injectable } from '@angular/core';
/*************************************************/
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { Router } from '@angular/router';

import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // API path
  url = this.configurationService.urlWebapi;
  private loggedIn = new BehaviorSubject<boolean>(false);
  isLoggedIn$: boolean;
  constructor(
    private router: Router,
    private http: HttpClient,
    private configurationService: ConfigurationService,
    /*private localStorageService: LocalStorageService*/
  ) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


  // Verify user credentials on server to get token
  loginForm(data): Observable<any> {
    return this.http.post<any>(this.url + 'api/conx/login.php', data)
      .pipe(
        retry(2),
        catchError(this.handleError)
      ); /**/
  }
  // After login save token and other values(if any) in localStorage

  setUser(resp: any) {
    localStorage.setItem('firstconnect', resp.Util_PremiereCnx);
    localStorage.setItem('login', resp.Util_Email);
    localStorage.setItem('access_token', resp.jwt);
    localStorage.setItem('UserID', resp.Id_Utilisateur);
    localStorage.setItem('UtilIDDirection', resp.Util_IDDirection);
    localStorage.setItem('prenoms', resp.Util_Nomprenoms);
    localStorage.setItem('expireAt', resp.expireAt);
    localStorage.setItem('profilid', resp.profil_id);
    localStorage.setItem('grpCircuitdmd', resp.grp_Circuit_dmd);
    localStorage.setItem('DirInitial', resp.Dir_Initial);
    localStorage.setItem('cvalidationbp', resp.Util_validateur);
    localStorage.setItem('cali', '16');
    localStorage.setItem('Utilapplbp', resp.Util_appl_bp);
    localStorage.setItem('Utilapplbc', resp.Util_appl_bc);

    this.isLoggedIn$ = this.isLoggedIn;
    /*  if (resp.premierecnx === '0') {
  
        this.router.navigate(['/user/reset']);
      } else {
        this.router.navigate(['/dashboard'])
          .then(() => {
            window.location.reload();
          });
      }*/
  }

  /*isAdminUser():boolean {
    if (localStorage.getItem('utilprofl') === '0') {
        return true; 
    }
    return false;
}*/

  getPermisData(profilid): Observable<any> {
    return this.http.get<any>(this.url + 'bpProfil/read/utilisateurpermission.php?id=' + profilid);
  }

  // Checking if token is set
  get isLoggedIn() {
    return localStorage.getItem('access_token') != null && localStorage.getItem('Prmierconnecio') !== '1';
  }


  /* logout() {
     localStorage.clear();
     this.loggedIn.next(false);
     this.router.navigate(['/login']);
   }
 
   logoutFirstConnexion() {
     localStorage.clear();
     this.loggedIn.next(false);
     this.router.navigate(['/user/reset']);
   }*/

  // Get data from server for Dashboard
  getData(data): Observable<any> {
    return this.http
      .post<any>(this.url + 'api/read/authentication.php', data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }


  /*Premiere connexion*/
  PremiereConnexion(data): Observable<any> {
    return this.http.post<any>(this.url + 'premierecnx.php', data);
  }

}
