<div
  class="wrapper"
  style="background-image: url('assets/images/bg-registration-form-2.jpg')"
>
  <div class="inner">
    <form [formGroup]="validedForm" (ngSubmit)="saveForm(validedForm.value)">
      <input
        type="text"
        hidden
        formControlName="IDParticipation"
        placeholder="IDParticipation"
      />
      <h3>Formulaire d'inscription</h3>
      <!-- WOZO VACANCES 2023-->

      <div class="form-wrapper">
        <label for="">NOM DU GROUPE <span class="highlight">*</span></label>
        <input
          type="text"
          class="form-control"
          formControlName="GroupNom"
          placeholder="Nom du groupe"
        />
      </div>

      <div class="form-wrapper">
        <label for=""
          >NOM ET PRENOMS DU RESPONSABLE <span class="highlight">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="GroupRespoNomPrenoms"
          placeholder="Nom et prénoms"
        />
      </div>
      <div class="form-wrapper">
        <label for=""
          >TELEPHONE DU RESPONSABLE <span class="highlight">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="GroupContact"
          placeholder="Téléphone du responsable du groupe"
          maxlength="10"
        />
      </div>
      <div class="form-wrapper">
        <label for="">EMAIL <span class="highlight">*</span></label>
        <input
          type="text"
          class="form-control"
          formControlName="GroupEmail"
          placeholder="Email du responsable du groupe"
        />
      </div>
      <div class="form-wrapper">
        <label for=""
          >VILLE DE PRESELECTION <span class="highlight">*</span></label
        >
        <select
          name="pets"
          id="pet-select"
          formControlName="GroupVillePreselection"
          class="form-control"
        >
          <option value="">
            ---- Choisissez votre ville de présélection ----
          </option>
          <option value="ABIDJAN">ABIDJAN</option>
          <option value="AGNIBILEKRO">AGNIBILEKRO</option>
          <option value="BOUAKE">BOUAKE</option>
          <option value="DALOA">DALOA</option>
          <option value="ABOISSO">ÉBOUÉ - ABOISSO</option>
          <option value="KORHOGO">KONG</option>
          
        </select>
      </div>

      <div style="font-size: small; padding-bottom: 10px">
        <!--En cliquant sur INSCRIPTION, vous acceptez le <a [routerLink]="['/routePath']">règlement</a> du concours..*/-->
        Tous les champs identifiés par un astérisque
        <span class="highlight">*</span> sont obligatoire.
      </div>
      <!--button>s'enregistrer</button-->
      <div
        class="text-center"
        style="margin-top: 0px"
        *ngIf="statusbtn; else notShow"
      >
        <button
          type="submit"
          class="btn btn-danger btn-sm text-white"
          [disabled]="statusbtn"
          style="text-transform: uppercase"
        >
          <span>
            <span class="spinner-border spinner-border-sm"></span>
            Traitement en cours, veuillez patienter svp!
          </span>
        </button>
      </div>

    </form>

    <!--div class="innerInformation">dff</div-->
  </div>
</div>

<ng-template #notShow>
  <div class="text-center" style="margin-top: 0px">
    <button
      type="submit"
      class="btn btn-danger btn-sm text-white"
      [disabled]="!validedForm.valid"
      style="text-transform: uppercase"
    >
      INSCRIPTION
    </button>
  </div>
</ng-template>

<!--button (click)="openConfirmationDialog()" type="button" class="btn btn-primary">Open dialog</button>

<p>Open the console to see log statements.</p-->
