import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  ValidedForm: FormGroup;
  isLoadingResults = false;
  rep;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,) {
    this.ValidedForm = this.fb.group({
      login: ['', Validators.required],
      motdepasse: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    /* this.authenticationService.logout();*/
  }


  login(values) {
    this.isLoadingResults = true;
    const ValidedFormData = new FormData();
    ValidedFormData.append('Util_Email', values.login);
    ValidedFormData.append('Util_Mdp', values.motdepasse);

    this.authenticationService.loginForm(ValidedFormData).subscribe(response => {
      this.rep = response;
      if (this.rep.success === true){
        this.isLoadingResults = false;
        this.toastrService.success('Ok ', 'Succes');

        // Sauvegarde des données utilisateur
        localStorage.setItem('firstconnect', this.rep.results.Util_PremiereCnx);
        localStorage.setItem('login', this.rep.results.Util_Email);
        localStorage.setItem('access_token', this.rep.results.jwt);
        localStorage.setItem('UserID', this.rep.results.Id_Utilisateur);
        localStorage.setItem('UtilIDDirection', this.rep.results.Util_IDDirection);
        localStorage.setItem('prenoms', this.rep.results.Util_Nomprenoms);
        localStorage.setItem('expireAt', this.rep.results.expireAt);
        localStorage.setItem('profilid', this.rep.results.profil_id);
        localStorage.setItem('grpCircuitdmd', this.rep.results.grp_Circuit_dmd);
        localStorage.setItem('DirInitial', this.rep.results.Dir_Initial);
        localStorage.setItem('cvalidationbp', this.rep.results.Util_validateur);
        localStorage.setItem('cali', '16');
        localStorage.setItem('Utilapplbp', this.rep.results.Util_appl_bp);
        localStorage.setItem('Utilapplbc', this.rep.results.Util_appl_bc);

        // redirection sur TB
        window.location.href = 'backend/dashboard';

      } else {
        this.isLoadingResults = false;
        this.toastrService.error('Echec de l\'authentification', 'Erreur');
        localStorage.clear();
      }

      this.isLoadingResults = false;
    },
      error => {
        this.isLoadingResults = false;
        this.toastrService.error('Un problème est survenu', 'Erreur');
        localStorage.setItem('isConnected', 'false');

      }); /**/
  }

}
