import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InscriptionService } from 'src/app/services/inscription.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-inscriptions',
  templateUrl: './inscriptions.component.html',
  styleUrls: ['./inscriptions.component.css']
})
export class InscriptionsComponent implements OnInit {
  public IDParticipation: any = Math.floor(Math.random() * (171807 - 342978 + 1) + 171807);
  public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  validedForm: FormGroup;
  statusbtn: boolean = false;
  submitted = false;
  IDParticipationGener: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private inscriptionService: InscriptionService,
    private configurationService: ConfigurationService,
    private datePipe: DatePipe,) {
    this.validedForm = this.fb.group({
      IDParticipation: [this.IDParticipation],
      GroupNom: ['', Validators.required],
      GroupContact: ['', Validators.required],
      GroupEmail: ['', Validators.required],
      GroupVillePreselection: ['', Validators.required],
      GroupDateInscrit: [],
      GroupRespoNomPrenoms: ['', Validators.required],
    });
  }




  ngOnInit(): void {
    /*this.openConfirmationDialog();*/
  }


  saveForm(values) {
    this.statusbtn = true;
    const ValidedFormData = new FormData();
    ValidedFormData.append('IDParticipation', values.IDParticipation);
    ValidedFormData.append('GroupNom', values.GroupNom);
    ValidedFormData.append('GroupContact', values.GroupContact);
    ValidedFormData.append('GroupEmail', values.GroupEmail);
    ValidedFormData.append('GroupVillePreselection', values.GroupVillePreselection);
    ValidedFormData.append('GroupDateInscrit', this.date);
    ValidedFormData.append('GroupRespoNomPrenoms', values.GroupRespoNomPrenoms);
    this.inscriptionService.createGroupeInscriptionWozo(ValidedFormData).subscribe(result => {
      if (result.success == true) {
        this.toastr.success(result.message);
        //        this.toastr.success(result.message, 'SUCCES');
        this.statusbtn = false;
        this.generateArticleID();
        this.openConfirmationDialog();

      } else {
        this.toastr.error(result.message);
        //this.toastr.error(result.message, 'MESSAGE D\'ERREUR');
        this.statusbtn = false;
      }

    });
  }


  generateArticleID() {
    this.validedForm.reset();
    this.IDParticipationGener = Math.floor(Math.random() * (171807 - 342978 + 1) + 171807);
    this.validedForm.controls['IDParticipation'].setValue(this.IDParticipationGener);
    this.validedForm.controls['GroupVillePreselection'].setValue('');
  }


  public openConfirmationDialog() {
    this.configurationService.confirm('Inscription enregistrée !', 'Cliquez sur le lien dans votre boite email pour finaliser votre inscription! Si vous ne recevez pas le mail de confirmation juste après votre inscription, veuillez vérifier dans votre boîte SPAM ou essayer de vous réinscrire avec une autre adresse e-mail.')
      .then((confirmed) => console.log('User confirmed:', confirmed))
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}



