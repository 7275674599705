<div class="wrapper" style="background-image: url('assets/images/bg-registration-form-2.jpg');">
 
    <div id="fountainG" *ngIf="isLoadingResults"><!--*ngIf="isLoadingResults"-->
        <div id="fountainG_1" class="fountainG"></div>
        <div id="fountainG_2" class="fountainG"></div>
        <div id="fountainG_3" class="fountainG"></div>
        <div id="fountainG_4" class="fountainG"></div>
        <div id="fountainG_5" class="fountainG"></div>
        <div id="fountainG_6" class="fountainG"></div>
        <div id="fountainG_7" class="fountainG"></div>
        <div id="fountainG_8" class="fountainG"></div>
    </div>
    
    <div class="innerConfirm" *ngIf="this.resultIDparticipant"><!---->
        <div class="row">

            <div class="col-md-6" style="font-size: 19px;"> 
                
        
                    <h3>Félicitations,</h3>
                    <p>Votre inscription à WOZO VACANCES a bien été pris en compte. </p>

                    <p> Rendez vous à <b>{{this.GroupVillePreselection | uppercase}}</b> avec le code suivant: <span style="color: red; font-weight: 600;">{{this.resultIDparticipant| uppercase}}</span> .</p>
                    <!--p> Un mail de confirmation vous a été envoyé.</p-->
                    <p>La date précise des présélections vous sera communiquée par téléphone ou email par l'équipe de wozo. </p>

           <!--p>Rendez-vous à la RTI avec le code <span style="color: red; font-weight: 600;">{{this.resultIDparticipant}}</span> de confirmation. </p-->
                    <p>La RTI vous remercie pour votre confiance !</p>
       
            </div>
            <!--div class="col-md-6">sldù</div-->
        </div>

   
            <div style="padding-left: 140px; padding-top: 10px; padding-bottom: 10px;">
               <img src="../../../assets/images/tick.png" width="90" alt="" srcset="">
            </div>
        
            <div style="margin-top: 0px;padding-left: 145px;">
                <button [routerLink]="['/registrations/add']" class="btn btn-success btn-sm text-white" style="text-transform: uppercase;">
                  <span> Quitter </span>
                </button> 
        </div>

      </div>


      
  </div>

