<nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
  <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#"
    >ADMIN WOZO VACANCES</a
  >
  <input
    class="form-control form-control-dark w-100"
    type="text"
    placeholder="Search"
    aria-label="Search"
  />
  <ul class="navbar-nav px-3">
    <li class="nav-item text-nowrap">
      <a class="nav-link" (click)="deconnexion()" style="cursor: pointer"
        >DECONNEXION</a
      >
    </li>
  </ul>
</nav>

<div class="container-fluid">
  <div class="row">
    <nav class="col-md-2 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <ul class="nav flex-column">
          <li class="nav-item text-center">
            <img
              class=""
              src="http://wozovacances.rti.ci/assets/images/logo-wozo-2021.jpg"
              alt=""
            />
          </li>
          <li class="nav-item">
            <a class="nav-link active text-center" href="#">
              INSCRIPTION <br />WOZO VACANCES
            </a>
          </li>
          <li class="nav-item">
            <img
              class=""
              width="424"
              height="490"
              src="http://wozovacances.rti.ci/assets/images/administration-left-imgx.jpg"
              alt=""
            />
          </li>
        </ul>
      </div>
    </nav>

    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom"
      >
        <!--{{'Liste des inscrits 2021' | uppercase}} - -->
        <h2 class="h3">
          <span style="color: red" *ngIf="this.TotalInscrit"
            >TOTAL {{ this.TotalInscrit }}</span
          >
        </h2>
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group mr-2">
            <button
              [useExistingCss]="true"
              printSectionId="pdfTable"
              ngxPrint
              class="btn btn-danger mr-2"
            >
              <i class="fas fa-print"></i> IMPRIMER
            </button>
            <button (click)="ExportTOExcel()" class="btn btn-success">
              <i class="fa fa-file-excel-o" aria-hidden="true"></i> EXPORTER
              VERS EXCEL
            </button>

            <!--button class="btn btn-sm btn-outline-secondary">EXPORT EXCEL</button>
                        <button class="btn btn-sm btn-outline-secondary">EXPORT PDF</button-->
          </div>
        </div>
      </div>
      <div
        class="row col-12"
        style="margin: auto; background-color: bisque; padding: 15px"
      >
        <form
          [formGroup]="RechercheForm"
          (ngSubmit)="onSubmitForm(RechercheForm.value)"
        >
          <div class="row col-12">
            <div class="row col-4">
              <div class="form-wrapper">
                <label>DATE DEBUT</label>
                <input
                  class="form-control"
                  type="date"
                  formControlName="date_debut"
                />
              </div>
            </div>
            <div class="row col-4">
              <div class="form-wrapper">
                <label>DATE FIN</label>
                <input
                  class="form-control"
                  type="date"
                  formControlName="date_fin"
                />
              </div>
            </div>
            <div class="row col-4">
              <div class="form-wrapper">
                <label for="">FILTRE CONFIRMATION</label>
                <select
                  name="filtre"
                  id="filtre"
                  formControlName="filtre"
                  class="form-control"
                >
                  <option value="">--- Choisissez le filtre ---</option>
                  <option value="2">TOUS LES ENREGISTREMENTS</option>
                  <option value="1">CONFIRMES</option>
                  <option value="0">PAS CONFIRMES</option>
                </select>
              </div>
            </div>
          </div>

          <div class="text-center" style="margin-top: 10px">
            <button
              type="submit"
              class="btn btn-info btn-sm text-white"
              [disabled]="RechercheForm.invalid"
              style="text-transform: uppercase"
            >
              LANCER LA RECHERCHE
            </button>
          </div>
        </form>

        <mat-form-field class="col-8">
          <label for=""> &nbsp; </label>
          <mat-label> Rechercher rapide</mat-label>

          <input matInput (keyup)="applyFilter($event)" #input />
        </mat-form-field>
        <div class="row col-3">
          <div class="form-wrapper">
            <label for="">VILLE DE PRESELECTION </label>
            <select
              name="ville"
              id="ville"
              formControlName="ville"
              class="form-control"
              (change)="filtreville($event)"
            >
              <option value="" selected>--- Choisissez une ville ---</option>
              <option value="ABIDJAN">ABIDJAN</option>
              <option value="AGNIBILEKRO">AGNIBILEKRO</option>
              <option value="BOUAKE">BOUAKE</option>
              <option value="DALOA">DALOA</option>
              <option value="ABOISSO">ÉBOUÉ - ABOISSO</option>
              <option value="KORHOGO">KONG</option>
            </select>
          </div>
        </div>
      </div>

      <div id="fountainG" *ngIf="isLoadingResults">
        <!--*ngIf="isLoadingResults"-->
        <div id="fountainG_1" class="fountainG"></div>
        <div id="fountainG_2" class="fountainG"></div>
        <div id="fountainG_3" class="fountainG"></div>
        <div id="fountainG_4" class="fountainG"></div>
        <div id="fountainG_5" class="fountainG"></div>
        <div id="fountainG_6" class="fountainG"></div>
        <div id="fountainG_7" class="fountainG"></div>
        <div id="fountainG_8" class="fountainG"></div>
      </div>

      <div class="table-responsive">
        <!-- loader  -->

        <div class="tt-bord">
          LISTE DES INSCRIPTIONS WOZO VACANCES<br />
        </div>
        <!-- loader end -->
        <table
          class="table table-bordered table-striped dataTable"
          mat-table
          [dataSource]="dataSource"
          matSort
        >
          <!-- ID Column -->
          <ng-container matColumnDef="GroupDateInscrit">
            <th *matHeaderCellDef mat-sort-header><b>Date</b></th>
            <td *matCellDef="let ungroup">
              {{ ungroup.GroupDateInscrit | date : "dd/MM/yyyy" }}
            </td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container
            matColumnDef="IDParticipation"
            style="width: 50px; text-align: center"
          >
            <th *matHeaderCellDef mat-sort-header>Code</th>
            <td *matCellDef="let ungroup">
              {{ ungroup.IDParticipation }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="GroupNom">
            <th *matHeaderCellDef mat-sort-header>Groupe</th>
            <td *matCellDef="let ungroup">
              {{ ungroup.GroupNom }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="GroupVillePreselection">
            <th *matHeaderCellDef mat-sort-header>Ville de préselection</th>
            <td *matCellDef="let ungroup">
              {{ ungroup.GroupVillePreselection }}
            </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="GroupRespoNomPrenoms">
            <th *matHeaderCellDef mat-sort-header>Responsable</th>
            <td *matCellDef="let ungroup">
              {{ ungroup.GroupRespoNomPrenoms }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="GroupContact">
            <th *matHeaderCellDef mat-sort-header>Contact</th>
            <td *matCellDef="let ungroup">
              {{ ungroup.GroupContact }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="GroupEmail">
            <th *matHeaderCellDef mat-sort-header>Email</th>
            <td *matCellDef="let ungroup">
              {{ ungroup.GroupEmail }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="GroupEmailConfim">
            <th *matHeaderCellDef mat-sort-header>Confirmé</th>
            <td *matCellDef="let ungroup">
              <span
                *ngIf="ungroup.GroupEmailConfim == 1"
                class="badge badge-success"
                >CONFIRME</span
              >
              <span
                *ngIf="ungroup.GroupEmailConfim == 0"
                class="badge badge-danger"
                >PAS CONFIRME</span
              >
            </td>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="boutons">
            <th *matHeaderCellDef mat-sort-header style="width: 5px"></th>
            <td *matCellDef="let ungroup">
              <div class="btn-group">
                <!--button (click)="questionSupprime(ungroup.ID_Inscription)" type="button" class="btn  btn-danger btn-xs" style="margin-left: 5px;" data-toggle="modal" data-target="#modal-info">
                                    <i class="fas fa-trash"></i></button-->
              </div>
              <!--div *ngIf="tickets.Ouvert == 1" class="form-group">
                                <span *ngIf="tickets.Niveau == 'BASSE'" class="badge badge-success">BASSE</span>
                                <span *ngIf="tickets.Niveau == 'NORMAL'" class="badge badge-info">NORMAL</span>
                                <span *ngIf="tickets.Niveau == 'ELEVE'" class="badge badge-warning">ELEVE</span>
                                <span *ngIf="tickets.Niveau == 'URGENT'" class="badge badge-danger">URGENT</span>
                            </div-->
            </td>
          </ng-container>
          <!-- Color Column -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let ungroup; columns: displayedColumns"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              Aucune données "{{ input.value }}"
            </td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </main>
  </div>
</div>

<div id="pdfTable" pdfTable hidden>
  <!---->
  <table width="100%">
    <tr>
      <td colspan="2">
        <table width="100%">
          <tr>
            <th width="61%" scope="col">
              <div>
                <div
                  class="invoice-left"
                  style="padding-top: 25px; text-align: left"
                >
                  <span style="text-align: center; text-transform: uppercase"
                    >Direction des Systèmes d'Information
                  </span>
                </div>
              </div>
            </th>
            <th width="35%">
              <div style="text-align: right">
                <h5 style="font-size: 8px">RTI wozovacances APP v1.0</h5>
                <img
                  class="logo"
                  src="http://wozovacances.rti.ci/assets/images/logo-wozo-2021.jpg"
                  alt="logo"
                  width="100px"
                />
              </div>
            </th>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <div class="tt-bord">
          LISTE DES INSCRIPTIONS WOZO VACANCES - TOTAL
          {{ this.TotalInscrit }}<br />
        </div>
      </td>
    </tr>

    <tr>
      <td class="tt-sous-glob" style="text-align: left; padding-left: 10px">
        <!--DATE: {{'PrintDemandeDate' | date: 'dd/MM/yyyy'}}-->
      </td>
      <td
        class="tt-sous-glob"
        style="text-align: right; padding-right: 10px; color: red"
      >
        <span *ngIf="fdatedebut">
          PERIODE DU: {{ fdatedebut | date : "dd/MM/yyyy" }} AU
          {{ fdatefin | date : "dd/MM/yyyy" }}</span
        >
      </td>
    </tr>

    <tr>
      <td colspan="2">
        <table width="100%" class="cMonTableau4" #TABLE>
          <!--*ngIf="this.grpCircuitdmd==5 || !ListeachatDirect"-->
          <thead>
            <tr>
              <th width="2%"></th>
              <th class="list-head-tt">DATE</th>
              <th class="list-head-tt">CODE</th>
              <th class="list-head-tt">GROUPE</th>
              <th class="list-head-tt">VILLE DE PRESELECTION</th>
              <th class="list-head-tt">RESPONSABLE</th>
              <th class="list-head-tt">CONTACT</th>
              <th class="list-head-tt">EMAIL</th>
              <th class="list-head-tt">CONFIRME</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let bpach of PrintListeInscrit; let i = index">
              <!---->
              <td class="text-center">{{ i + 1 }}</td>
              <!--{{i + 1}}-->
              <td class="list-head">
                {{ bpach.GroupDateInscrit | date : "dd/MM/yyyy" }}
              </td>
              <td class="list-head" style="text-align: left">
                {{ bpach.IDParticipation | uppercase }}
              </td>
              <td class="list-head" style="text-align: left">
                {{ bpach.GroupNom | uppercase }}
              </td>
              <td class="list-head">
                {{ bpach.GroupVillePreselection | uppercase }}
              </td>
              <td class="list-head" style="text-align: left">
                {{ bpach.GroupRespoNomPrenoms | uppercase }}
              </td>
              <td class="list-head">{{ bpach.GroupContact | uppercase }}</td>
              <td class="list-head" style="text-align: left">
                {{ bpach.GroupEmail }}
              </td>
              <td class="list-head">
                <span
                  *ngIf="bpach.GroupEmailConfim == 1"
                  class="badge badge-success"
                  >CONFIRME</span
                >
                <span
                  *ngIf="bpach.GroupEmailConfim == 0"
                  class="badge badge-danger"
                  >PAS CONFIRME</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="2">&nbsp;</td>
    </tr>

    <tr>
      <td></td>
    </tr>
    <tr>
      <td colspan="2" style="text-align: right; margin-top: 20px">
        <div class="pull-right list-head-footer">
          <!--Imprimé le {{'Todate' | date: 'dd/MM/yyyy'}} par {{'prenoms'}}-->
        </div>
      </td>
    </tr>
  </table>
</div>
