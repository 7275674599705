import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardGuard } from './guards/guard.guard';
import { DashboardComponent } from './page/administration/dashboard/dashboard.component';
import { LoginComponent } from './page/administration/login/login.component';
import { ConfirmationInscriptionComponent } from './page/confirmation-inscription/confirmation-inscription.component';
import { InscriptionsComponent } from './page/inscriptions/inscriptions.component';
import { ReglementsduconcoursComponent } from './page/reglementsduconcours/reglementsduconcours.component';


const routes: Routes = [
  { path: 'reglements/wozo', component: ReglementsduconcoursComponent },
  { path: 'confirm/wozo/:id', component: ConfirmationInscriptionComponent },
  { path: 'registrations/add', component: InscriptionsComponent },

  /**********BACKEND ACCESS************************* */
  { path: 'backend/login', component: LoginComponent },
  { path: 'backend/dashboard', component: DashboardComponent , canActivate: [GuardGuard]},

  { path: '', redirectTo: 'registrations/add', pathMatch: 'full' },
  { path: '**', redirectTo: 'registrations/add' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
