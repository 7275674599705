import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';


@Injectable({
  providedIn: 'root'
})
export class InscriptionService {
  url = this.configurationService.urlWebapi;
  constructor(
    private httpClient: HttpClient,
    private configurationService: ConfigurationService) { }

  createGroupeInscriptionWozo(data): Observable<any> {
    return this.httpClient.post<any>(this.url + 'api/registrations/create/registration.php', data);
  }

  verificationGroupeInscriptionWozo(data): Observable<any> {
    return this.httpClient.post<any>(this.url + 'api/registrations/create/confirmation.php', data);
  }

  listeInscription(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url + 'api/registrations/read/listeregistration.php');
  }

  listeInscriptionfiltre(data): Observable<any> {
    return this.httpClient.post<any>(this.url + 'api/registrations/read/listeregistrationfiltre.php', data);
  }
}
